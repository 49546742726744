import React from "react"
import Sidebar from "../components/layout/sidebar"
import Staff from "../components/contacts/staff"
import WorkingHours from "../components/contacts/working-hours"
import useStaff from "../hooks/use-staff-en"
import useContacts from "../hooks/use-contacts-en"

export default function Contacts() {
  const staffData = useStaff()
  const contactsData = useContacts()
  return (
    <Sidebar
      heading="Contacts"
      leftColumn={<WorkingHours en data={contactsData} />}
      sticky
    >
      <Staff data={staffData} />
    </Sidebar>
  )
}
